// @flow

import type { Chapter } from '../model/type/Chapter';
import type { Action } from '../actions/types';
import { FETCH_CHAPTERS } from '../actions/types';
import { FULFILLED } from 'redux-promise-middleware';
import { createChaptersFromApiInput } from '../model/factory/chapterFactory';

export type ChaptersReducerState = Array<Chapter> | null;

export default function(currentState: ChaptersReducerState = null, action: Action): ChaptersReducerState {
    switch (action.type) {
        case `${FETCH_CHAPTERS}_${FULFILLED}`:
            return createChaptersFromApiInput(action.payload.data);

        default:
            return currentState;
    }
}
