// @flow

import type { CurrentSite } from '../model/type/CurrentSite';
import type { Action } from '../actions/types';

export type CurrentSiteReducerState = CurrentSite;

const INITIAL_STATE = {
    identifier: '',
    title: '',
    spritemapPath: ''
};

export default function(currentState: CurrentSiteReducerState = INITIAL_STATE, action: Action): CurrentSiteReducerState {
    switch (action.type) {
        default:
            return currentState;
    }
}
