// @flow
declare var Routing: any;

import { extractPathWithPlaceholdersFromRoute } from './pathWithPlaceholdersExtractor';

export function createHomePath(): string {
    return Routing.generate('home', { host: Routing.getHost() });
}

export function createCourseEntryPath(): string {
    return Routing.generate('course', { host: Routing.getHost() });
}

export function createCourseChapterPath(slug: ?string = null): string {
    if (slug) {
        return Routing.generate('course_chapter', { host: Routing.getHost(), slug: slug });
    }

    return extractPathWithPlaceholdersFromRoute('course_chapter');
}

export function createChapterIndexApiPath(): string {
    return Routing.generate('api_course_chapters', { host: Routing.getHost() });
}

export function createChapterExamPath(chapterSlug: ?string): string {
    if (chapterSlug) {
        return Routing.generate('course_exam', { host: Routing.getHost(), slug: chapterSlug });
    }

    return extractPathWithPlaceholdersFromRoute('course_exam');
}

export function createGetChapterExamApiPath(chapterSlug: string): string {
    return Routing.generate('api_course_exam', { host: Routing.getHost(), slug: chapterSlug });
}

export function createCourseFinishedPath(): string {
    return Routing.generate('course_finish', { host: Routing.getHost() });
}

export function createCourseDownloadCertificatePath(name: string, companyName: string): string {
    return Routing.generate('api_course_download_certificate', {
        host: Routing.getHost(),
        name: name,
        companyName: companyName
    });
}
