// @flow
import React from 'react';
import type { Chapter } from '../../../model/type/Chapter';
import ChapterHeader from './ChapterHeader';
import ChapterBlocks from './ChapterBlocks';
import Classnames from 'classnames';
import ChapterExamButton from './ChapterExamButton';
import type { FinishedChapter } from '../../../model/type/FinishedChapter';

type Props = {
    currentChapter: Chapter,
    finishedChapters: Array<FinishedChapter>,
    onAnimationStart?: Function,
    onAnimationEnd?: Function
};

type State = {
    isUpdating: boolean,
    isInitialized: boolean,
    animateDirection: string | null,
    currentChapter: Chapter
}

const DELAY_BEFORE_UPDATING_MS = 500;
const ANIMATE_DIRECTION_UP = 'UP';
const ANIMATE_DIRECTION_DOWN = 'DOWN';

class ChapterContent extends React.Component<Props, State> {

    constructor(props: Object) {
        super(props);

        this.state = {
            isUpdating: false,
            isInitialized: false,
            animateDirection: null,
            currentChapter: this.props.currentChapter
        };

        (this: any)._onAnimationEnd = this._onAnimationEnd.bind(this);
    }

    componentDidMount() {
        // timeout is needed because otherwise the 'is-initialized' class will be set on render, causing to break the transition
        setTimeout(() => {
            this.setState({
                isInitialized: true
            });
        }, 1);

        (this: any).chapterContent.addEventListener('animationend', this._onAnimationEnd);
    }

    componentWillUnmount() {
        (this: any).chapterContent.removeEventListener('animationend', this._onAnimationEnd);
    }

    shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        // Update component when isInitialized changes
        if (this.state.isInitialized !== nextState.isInitialized) {
            return true;
        }

        // Only update component when isUpdating is false and currentChapters (props) are not equal to each other
        // this will trigger another 'shouldComponentUpdate' caused by componentDidUpdate first setState (see next step *1)
        if (!this.state.isUpdating && this.props.currentChapter.id !== nextProps.currentChapter.id) {
            return true;
        }

        // *1
        // Component should update when isUpdating differs from the oldState (triggered by componentDidUpdate first this.setState),
        // This will trigger the animation, when the animation is running for 500ms *2 will be triggered
        if (this.state.isUpdating !== nextState.isUpdating) {
            return true;
        }

        //  *2
        // Component should update state currentChapter when the differ from the oldState
        // This will cause to re-render chapter__content
        if (this.state.currentChapter.id !== nextState.currentChapter.id) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        var { onAnimationStart } = this.props;

        // stop when component is already updating or when isInitialized state changes
        if (prevState.isUpdating || !prevState.isInitialized) {
            return;
        }

        if (onAnimationStart) {
            onAnimationStart();
        }

        this.setState({
            isUpdating: true,
            animateDirection:  prevState.currentChapter.rank > this.props.currentChapter.rank ? ANIMATE_DIRECTION_DOWN : ANIMATE_DIRECTION_UP
        }, () => {

            // before content is changing set window to top
            // if not user would see the window position of previous chapter
            window.scrollTo(0, 0);

            setTimeout(() => {
                this.setState({
                    currentChapter: this.props.currentChapter
                });
            }, DELAY_BEFORE_UPDATING_MS);
        });
    }

    _onAnimationEnd() {
        var { onAnimationEnd } = this.props;

        this.setState({
            isUpdating: false,
            animateDirection: null
        });

        if (onAnimationEnd) {
            onAnimationEnd();
        }
    }

    render() {
        var { currentChapter } = this.state;
        var { finishedChapters } = this.props;

        var chapterClassNames = Classnames('chapter__content', {
            'is-initialized': this.state.isInitialized,
            'is-updating': this.state.isUpdating,
            'is-animating-down': this.state.animateDirection === ANIMATE_DIRECTION_DOWN
        });

        return (
            <div className={ chapterClassNames } ref={(chapterContent) => (this: any).chapterContent = chapterContent }>
                <ChapterHeader
                    title={ currentChapter.title }
                    intro={ currentChapter.intro }
                    rank={ currentChapter.rank }
                />
                <ChapterBlocks blocks={ currentChapter.blocks } />
                <div className="chapter__content-exam-btn">
                    <ChapterExamButton
                        currentChapter={ currentChapter }
                        finishedChapters={ finishedChapters }
                    />
                </div>
            </div>
        );
    }
}

export default ChapterContent;
