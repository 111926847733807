// @flow
import type { Chapter } from '../type/Chapter';
import { createBlocksFromApiData } from './blockFactory';

function _createChapterFromApiInput(apiInput: Object, index): Chapter {
    return {
        id: apiInput.id,
        slug: apiInput.slug,
        title: apiInput.title,
        intro: apiInput.intro,
        blocks: createBlocksFromApiData(apiInput.blocks),
        hasExam: apiInput.hasExam,
        rank: index + 1,
        seoMetadata: apiInput.seoMetadata
    };
}

export function createChaptersFromApiInput(apiInput: Object) {
    return apiInput.map((chapter, index) => _createChapterFromApiInput(chapter, index));
}
