// @flow
import React from 'react';

type Props = {
    identifier: string,
    additionalName?: ?string,
};

const LOGO_BASE_PATH = '/assets/website/images/site_logo/';

class Logo extends React.Component<Props> {
    render() {
        var { identifier, additionalName, ...otherProps } = this.props;

        var logoBasePath = LOGO_BASE_PATH + identifier;
        var logoPath = additionalName ? `${logoBasePath}_${additionalName}.svg` : `${logoBasePath}.svg`;

        return (
            <img src={ logoPath } {...otherProps} />
        );
    }
}

export default Logo;
