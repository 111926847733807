// @flow
import React from 'react';
import { createCourseChapterPath } from '../../../routing/urlGenerator';
import Classnames from 'classnames';
import type { Chapter } from '../../../model/type/Chapter';
import ChapterSidebarListItem from './ChapterSidebarListItem';
import type { FinishedChapter } from '../../../model/type/FinishedChapter';
import ChapterExamButton from './ChapterExamButton';

type Props = {
    isActive: boolean,
    isMobileView: boolean,
    linksAreDisabled: boolean,
    chapters: Array<Chapter>,
    currentChapter: Chapter,
    finishedChapters: Array<FinishedChapter>,
    onClickHandler: Function
}

class ChapterSidebarContent extends React.Component<Props> {
    _renderListItems() {
        var { chapters, currentChapter, onClickHandler, finishedChapters } = this.props;

        return chapters.map((chapter) => {
            var chapterIsFinished = finishedChapters.findIndex((finishedChapter) => chapter.id === finishedChapter.id) > -1;

            var listItemClassName = Classnames('chapter-sidebar__list-item', {
                'is-finished': chapterIsFinished,
                'is-active': currentChapter.rank === chapter.rank
            });

            var route = createCourseChapterPath(chapter.slug);

            return (
                <ChapterSidebarListItem
                    key={ chapter.id }
                    className={ listItemClassName }
                    route={ route }
                    chapter={ chapter }
                    isFinished={ chapterIsFinished }
                    onClickHandler={ onClickHandler }
                />
            );
        });
    }

    render() {
        var { isMobileView, isActive, linksAreDisabled, currentChapter, finishedChapters } = this.props;

        if (isMobileView && !isActive) {
            return null;
        }

        var chapterSidebarListClassName = Classnames('chapter-sidebar__list', {
            'is-disabled': linksAreDisabled
        });

        return (
            <div className="chapter-sidebar__content">
                <nav className="chapter-sidebar__nav">
                    <ul className={ chapterSidebarListClassName }>
                        { this._renderListItems() }
                    </ul>
                </nav>
                <ChapterExamButton
                    currentChapter={ currentChapter }
                    finishedChapters={ finishedChapters }
                />
            </div>
        );
    }
}

export default ChapterSidebarContent;
