// @flow
declare var Routing: any;

/**
 * to be able to generate a route without filling-in the placeholders, we need to extract the placeholder names
 * from the route information. If we use Routing.generate the placeholders are url encoded, which makes them
 * not usable with React Router.
 *
 * @param {String} routeName
 *
 * @returns {String}
 */
export function extractPathWithPlaceholdersFromRoute(routeName: string): string {
    var route = Routing.getRoute(routeName);

    var pathParts = route.tokens.map((tokenInfo) => {
        var type = tokenInfo[0],
            lastIndex = tokenInfo.length - 1;

        switch (type) {
            case 'variable':
                return `/:${tokenInfo[lastIndex]}`;

            case 'text':
                return tokenInfo[lastIndex];

            default:
                throw new Error(`Type ${type} not supported`);
        }
    });

    return pathParts.reverse().join('');
}
