// @flow
import { isDevelopmentEnvironment } from './environmentHelper';

export function trackPageView(path: string) {
    if (!window._st) {
        return;
    }

    // we need to reset existing tags to handle this as a new virtual page visit
    // Relay42 will trigger Google Analytics to register page view
    window._st('resetTags');
    window._st('setPageStructure', _convertPathToPipeSegments(path));
    window._st('loadTags');

    if (isDevelopmentEnvironment()) {
        console.info('tracked page with Relay42', _convertPathToPipeSegments(path)); // eslint-disable-line no-console
    }
}

export function sendCustomTrackingEvent(category: string, eventName: string, label: ?string = null, value: ?number = null) {
    if (!window.ga) {
        return;
    }

    window.ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: eventName,
        eventLabel: label,
        eventValue: value
    });

    if (isDevelopmentEnvironment()) {
        console.info('Send custom event with Google Analytics:', ...arguments); // eslint-disable-line no-console
    }
}

function _convertPathToPipeSegments(path: string): string {
    return path.substring(1).split('/').join('|');
}
