// @flow
import * as React from 'react';
import { createCourseEntryPath } from '../../routing/urlGenerator';

type Props = {
    children: React.Node
};

type State = {
    hasError: boolean,
    message: string
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hasError: false,
            message: ''
        };
    }

    componentDidCatch(error: Object) {
        this.setState({
            hasError: true,
            message: error.message
        });
    }

    render() {
        var { hasError, message } = this.state;

        if (!hasError) {
            return this.props.children;
        }

        return (
            <article className="section">
                <div className="container">
                    <div className="layout layout--align-center">
                        <div className="layout__item layout__item--lg-8">
                            <h1>Er heeft zich een probleem voorgedaan</h1>
                            <p>{ message }</p>
                            <a href={ createCourseEntryPath() } className="button button--primary">Naar de cursus</a>
                        </div>
                    </div>
                </div>
            </article>
        );
    }
}

export default ErrorBoundary;
