const ENVIRONMENT_DEVELOPMENT = 'dev';
const ENVIRONMENT_PRODUCTION = 'production';
const ENVIRONMENT_STAGING = 'staging';
const ENVIRONMENT_TEST = 'test';

const CUSTOMER_ENVIRONMENTS = [
    ENVIRONMENT_STAGING,
    ENVIRONMENT_PRODUCTION
];

const INTERNAL_ENVIRONMENTS = [
    ENVIRONMENT_DEVELOPMENT,
    ENVIRONMENT_TEST
];

var currentEnvironment = process.env.NODE_ENV;

/**
 * @returns {Boolean}
 */
export function isDevelopmentEnvironment() {
    return currentEnvironment === ENVIRONMENT_DEVELOPMENT;
}

/**
 * @returns {Boolean}
 */
export function isProductionEnvironment() {
    return currentEnvironment === ENVIRONMENT_PRODUCTION;
}

/**
 * @returns {Boolean}
 */
export function isCustomerEnvironment() {
    return CUSTOMER_ENVIRONMENTS.indexOf(currentEnvironment) !== -1;
}

/**
 * @returns {Boolean}
 */
export function isInternalEnvironment() {
    return INTERNAL_ENVIRONMENTS.indexOf(currentEnvironment) !== -1;
}
