// @flow
import React from 'react';
import { createHomePath } from '../../../routing/urlGenerator';
import Logo from '../../shared/Logo';
import type { CurrentSite } from '../../../model/type/CurrentSite';

type Props = {
    currentSite: CurrentSite,
    classNames?: string,
    onClickHandler: Function
}

class ChapterSidebarHeader extends React.Component<Props> {
    render() {
        var { currentSite, onClickHandler } = this.props;

        return (
            <div className="chapter-sidebar__header">
                <a href={ createHomePath() }>
                    <Logo identifier={ currentSite.identifier } className="header__logo" />
                </a>
                <button type="button"
                        onClick={ onClickHandler }
                        className="chapter-sidebar__toggle menu-icon"
                        aria-label="Menu openen">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
        );
    }
}

export default ChapterSidebarHeader;
