// @flow
import React from 'react';
import { createCourseChapterPath, createHomePath } from '../../../routing/urlGenerator';
import Logo from '../../shared/Logo';
import { connect } from 'react-redux';
import type { CurrentSiteReducerState } from '../../../reducers/currentSiteReducer';
import { Link } from 'react-router-dom';
import type { Chapter } from '../../../model/type/Chapter';
import type { GlobalState } from '../../../reducers/reducers';

type Props = {
    currentSite: CurrentSiteReducerState,
    classNames?: string,
    chapter: Chapter
}

type ReduxProps = {
    currentSite: CurrentSiteReducerState
}

class ExamHeader extends React.Component<Props> {
    render() {
        var { currentSite, chapter } = this.props;

        return (
            <div className="header header--exam exam__header">
                <div className="container container--fluid">
                    <div className="exam__header-content">
                        <a href={ createHomePath() }>
                            <Logo identifier={ currentSite.identifier }
                                  className="header__logo"
                                  additionalName="white"
                            />
                        </a>
                        <h1 className="exam__header-title">{ `${chapter.rank}. ${chapter.title}` }</h1>
                        <Link to={ createCourseChapterPath(chapter.slug) } className="exam__header-exit"/>
                    </div>
                </div>
            </div>
        );
    }
}

function _mapGlobalStateToProps(globalState: GlobalState): ReduxProps {
    return {
        currentSite: globalState.currentSite
    };
}

export default connect(_mapGlobalStateToProps)(ExamHeader);
