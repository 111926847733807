// @flow
import React from 'react';
import type { Question } from '../../../model/type/Question';
import ExamQuestionAnswer from './ExamQuestionAnswer';
import Classnames from 'classnames';
import type { Answer } from '../../../model/type/Answer';
import CustomScroll from 'react-custom-scroll';
import { sendCustomTrackingEvent } from '../../../helper/trackingHelper';

type Props = {
    question: Question,
    index: number,
    currentQuestion: number,
    totalQuestions: number,
    isCurrentQuestion: boolean,
    nextQuestionHandler: Function,
    resetQuestionHandler: Function,
    finishExamHandler: Function,
    isLastQuestion: boolean
}

type State = {
    isFlipped: boolean,
    style: Object,
    chosenAnswer: ?Answer,
    isAnsweredCorrectly: ?boolean,
}

const BASE_TRANSFORM_VALUE = 0.05;
const BASE_TOP_VALUE = 20;
const RESET_ANSWER_DELAY = 500;

class ExamQuestion extends React.Component<Props, State> {

    constructor(props: Object) {
        super(props);

        this.state = {
            isFlipped: false,
            isAnsweredCorrectly: null,
            chosenAnswer: null,
            style: {}
        };

        (this: any)._answerQuestion = this._answerQuestion.bind(this);
        (this: any)._resetQuestion = this._resetQuestion.bind(this);
        (this: any)._onNextQuestionClick = this._onNextQuestionClick.bind(this);
        (this: any)._onFinishExamClick = this._onFinishExamClick.bind(this);
    }

    componentDidMount() {
        var { index, totalQuestions } = this.props;

        this.setState({
            style: {
                zIndex: (totalQuestions - index),
                transform: `scale(${ 1 - (BASE_TRANSFORM_VALUE * index)})`,
                top: `${BASE_TOP_VALUE * index}px`
            }
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        var { index, currentQuestion } = this.props;

        if (prevProps.currentQuestion === this.props.currentQuestion) {
            return;
        }

        this.setState({
            style: {
                ...prevState.style,
                transform: `scale(${1 - (BASE_TRANSFORM_VALUE * (index - currentQuestion))}`,
                top: `${BASE_TOP_VALUE * (index - currentQuestion)}px`
            }
        });
    }

    _toggleFlipped() {
        this.setState({
            isFlipped: !this.state.isFlipped
        });
    }

    _answerQuestion(answer: Answer) {
        var { question } = this.props;

        // clone answer so it doesn't get updated when answers get shuffled which causes to display other letter while animating
        answer = { ...answer };

        this.setState({
            isAnsweredCorrectly: answer.isCorrect,
            chosenAnswer: answer
        });

        sendCustomTrackingEvent(
            'vragen',
            question.title,
            answer.isCorrect ? 'juist' : 'onjuist'
        );

        this._toggleFlipped();
    }

    _resetQuestion() {
        // reset answer with a small delay to have it visible when card is flipping
        setTimeout(() => {
            this.setState({
                isAnsweredCorrectly: null,
                chosenAnswer: null
            });
        }, RESET_ANSWER_DELAY);

        this.props.resetQuestionHandler();

        this._toggleFlipped();
    }

    _onNextQuestionClick() {
        this.props.nextQuestionHandler();
    }

    _onFinishExamClick() {
        this.props.finishExamHandler();
    }

    _renderButton() {
        var { isLastQuestion } = this.props;
        var { isAnsweredCorrectly } = this.state;
        var buttonClassName = 'button button--primary';

        if (!isAnsweredCorrectly) {
            return (
                <button className={ buttonClassName } onClick={ this._resetQuestion }>Opnieuw proberen</button>
            );
        } else if (isLastQuestion) {
            return (
                <button className={ buttonClassName } onClick={ this._onFinishExamClick }>Ga door met de cursus</button>
            );
        }

        return (
            <button className={ buttonClassName } onClick={ this._onNextQuestionClick }>Volgende vraag</button>
        );
    }

    _renderBack() {
        var { question } = this.props;
        var { isAnsweredCorrectly, chosenAnswer } = this.state;
        var titleClassName = Classnames('exam__question-explanation-title', {
            'is-correct': isAnsweredCorrectly
        });

        if (isAnsweredCorrectly === null) {
            return <div className="exam__question-back" />;
        }

        return (
            <div className="exam__question-back">
                <div className="exam__question-header">
                    <ExamQuestionAnswer answer={ chosenAnswer }/>
                </div>
                <div className="exam__question-explanation">
                    <CustomScroll>
                        <h2 className={ titleClassName }>{ isAnsweredCorrectly ? 'Gefeliciteerd, u hebt het goed' : 'Helaas, u hebt het fout'}</h2>
                        <p>{ question.explanation }</p>
                    </CustomScroll>
                </div>
                <div className="exam__question-button-wrapper">
                    { this._renderButton() }
                </div>
            </div>
        );
    }

    _renderFront() {
        var { index, question, totalQuestions } = this.props;

        return (
            <div className="exam__question-front">
                <div className="exam__question-header">
                    <div className="exam__question-position">
                        { `Vraag ${index + 1} / ${totalQuestions}` }
                    </div>
                    <h1 className="exam__question-title">{ question.title }</h1>
                </div>
                <div className="exam__question-answers-wrapper">
                    <CustomScroll>
                        <div className="exam__question-answers">
                            { question.answers.map((answer) =>
                                <ExamQuestionAnswer
                                    key={ answer.id }
                                    answer={ answer }
                                    onClickHandler={ this._answerQuestion }/>
                            )}
                        </div>
                    </CustomScroll>
                </div>
            </div>
        );
    }

    render() {
        var { style, isFlipped, isAnsweredCorrectly} = this.state;
        var { isCurrentQuestion } = this.props;
        var questionClassName = Classnames('exam__question', {
            'is-active': isCurrentQuestion,
            'is-flipped': isFlipped,
            'is-finished': !isCurrentQuestion && isAnsweredCorrectly
        });

        return (
            <div className={ questionClassName } style={ style }>
                { this._renderFront() }
                { this._renderBack() }
            </div>
        );
    }
}

export default ExamQuestion;
