// @flow
import { createStore as reduxCreateStore } from 'redux';
import reducers from '../reducers/reducers';
import { createMiddlewareChain } from '../middlewares/middlewareFactory';

export function createStore(history: Object, initialState: ?Object = {}): Object {
    return reduxCreateStore(
        reducers,
        initialState,
        createMiddlewareChain(history)
    );
}
