// @flow
import type { Exam } from '../model/type/Exam';
import { FULFILLED } from 'redux-promise-middleware';
import { CLEAR_EXAM, FETCH_EXAM, NEXT_EXAM_QUESTION, RESET_EXAM_QUESTION } from '../actions/types';
import type { Action } from '../actions/types';
import { createExamFromApiInput } from '../model/factory/examFactory';
import { shuffle } from '../helper/shuffleArrayHelper';
import type { Question } from '../model/type/Question';
import { convertNumberToLetter } from '../helper/convertNumberToLetter';

export type ExamReducerState = Exam | null;

function _shuffleCurrentQuestionAnswers(questions: Array<Question>, currentQuestionIndex: number): Array<Question> {
    return questions.map((question, index) => {
        if (index === currentQuestionIndex) {
            var shuffledAnswers = shuffle(question.answers);

            // because the array is shuffled rebuild the letter that matches the index
            question.answers = shuffledAnswers.map((answer, index) => {
                answer.letter = convertNumberToLetter(index);

                return answer;
            });
        }

        return question;
    });
}

function _handleResetExamQuestion(currentState: ExamReducerState): ExamReducerState {
    if (currentState === null) {
        return currentState;
    }

    return {
        ...currentState,
        questions: _shuffleCurrentQuestionAnswers(currentState.questions, currentState.currentQuestion)
    };
}

export default function(currentState: ExamReducerState = null, action: Action): ExamReducerState {
    switch (action.type) {
        case `${FETCH_EXAM}_${FULFILLED}`:
            return createExamFromApiInput(action.payload.data);
        case NEXT_EXAM_QUESTION:
            return {
                ...currentState,
                currentQuestion: (currentState ? currentState.currentQuestion + 1 : 0 )
            };
        case RESET_EXAM_QUESTION:
            return _handleResetExamQuestion(currentState);
        case CLEAR_EXAM:
            return null;
        default:
            return currentState;
    }
}
