// @flow
import React from 'react';
import { compose } from 'redux';
import FinishedCourseHeader from './finishedCourse/components/FinishedCourseHeader';
import FinishedCourseContent from './finishedCourse/components/FinishedCourseContent';
import type { GlobalState } from '../reducers/reducers';
import { connect } from 'react-redux';
import type { CurrentSiteReducerState } from '../reducers/currentSiteReducer';
import requireFinishedAllExams from '../hoc/requireFinishedAllExams';

type Props = {
    currentSite: CurrentSiteReducerState
}

type ReduxProps = {
    currentSite: CurrentSiteReducerState
}

const DEFAULT_COLORS = ['primary', 'secondary'];
const DEFAULT_ANIMATION_TYPES = ['slow', 'medium', 'fast'];
const CONFETTI_INTERVAL = 50;
const CONFETTI_REMOVE_TIMEOUT = 3000;

class FinishedCourse extends React.Component<Props> {

    componentDidMount() {
        this._renderConfetti();
    }

    componentWillUnmount() {
        clearInterval((this: any).confettiInterval);
    }

    _renderConfetti() {
        var finishedCourse = (this: any).finishedCourse;
        var confettiContainer = (this: any).confettiContainer;
        var confettiColors = DEFAULT_COLORS;
        var confettiAnimations = DEFAULT_ANIMATION_TYPES;

        (this: any).confettiInterval = setInterval(() => {
            const confettiEl = document.createElement('div');
            const confettiSize = (Math.floor(Math.random() * 3) + 7) + 'px'; // eslint-disable-line no-magic-numbers
            const confettiBackgroundClass = confettiColors[Math.floor(Math.random() * confettiColors.length)];
            const confettiLeft = (Math.floor(Math.random() * finishedCourse.offsetWidth)) + 'px';
            const confettiAnimation = confettiAnimations[Math.floor(Math.random() * confettiAnimations.length)];

            confettiEl.classList.add('confetti', 'confetti--animation-' + confettiAnimation, 'confetti--' + confettiBackgroundClass);
            confettiEl.style.left = confettiLeft;
            confettiEl.style.width = confettiSize;
            confettiEl.style.height = confettiSize;

            (confettiEl: any).removeTimeout = setTimeout(function() {
                (confettiEl: any).parentNode.removeChild(confettiEl);
            }, CONFETTI_REMOVE_TIMEOUT);

            confettiContainer.appendChild(confettiEl);
        }, CONFETTI_INTERVAL);
    }

    render() {
        var { currentSite } = this.props;

        return (
            <div className="finished-course" ref={ (finishedCourse) => (this: any).finishedCourse = finishedCourse }>
                <div className="confetti-container" ref={ (confettiContainer) => (this: any).confettiContainer = confettiContainer }/>
                <FinishedCourseHeader
                    currentSite={ currentSite }
                />
                <FinishedCourseContent />
            </div>
        );
    }
}

function _mapGlobalStateToProps(globalState: GlobalState): ReduxProps {
    return {
        currentSite: globalState.currentSite
    };
}

export default compose(
    requireFinishedAllExams,
    connect(_mapGlobalStateToProps)
)(FinishedCourse);

