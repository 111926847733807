// @flow
import { applyMiddleware, compose } from 'redux';
import { isDevelopmentEnvironment } from '../helper/environmentHelper';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import promiseMiddleware from 'redux-promise-middleware';
import localPersistingMiddleware from './localPersistingMiddleware';
import locationTrackingMiddleware from './locationTrackingMiddleware';

export function createMiddlewareChain(history: Object) {
    var middlewares: Array<Function> = [
        localPersistingMiddleware,
        thunkMiddleware,
        promiseMiddleware(),
        routerMiddleware(history),
        locationTrackingMiddleware
    ];

    // add access for Redux dev-tools extensions (https://github.com/zalmoxisus/redux-devtools-extension)
    var enhancedComposer = isDevelopmentEnvironment() && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined'
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

    return enhancedComposer(
        applyMiddleware(...middlewares)
    );
}
