// @flow
import type { Action } from '../actions/types';
import { LOCATION_CHANGE } from 'react-router-redux';
import { trackPageView } from '../helper/trackingHelper';

type Next = (action: Action) => void;

export default () => (next: Next) => (action: Action): any => {
    if (action.type === LOCATION_CHANGE) {
        var pathname = action.payload.pathname;

        if (!pathname) {
            return null;
        }

        trackPageView(pathname);
    }

    next(action);
};
