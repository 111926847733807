// @flow
import axios from 'axios';
import Queue from 'promise-queue';

const NR_OF_CONCURRENT_REQUESTS = 1;

var _requestQueue = new Queue(NR_OF_CONCURRENT_REQUESTS, Infinity);

function _appendToQueue(callback: Function): Promise<any> {
    return _requestQueue.add(callback);
}

export function get(url: string, queryParams: ?Object = {}): Promise<any> {
    return _appendToQueue(
        () => {
            return axios.get(url, {
                params: queryParams
            });
        }
    );
}

export function post(url: string, queryParams: ?Object = {}): Promise<any> {
    return _appendToQueue(
        () => {
            return axios.post(url, queryParams);
        }
    );
}
