// @flow
import React from 'react';

type Props = {}

class Loader extends React.Component<Props> {
    render() {
        return (
            <div className="loader">
                <div className="loader__circle loader__circle--1"></div>
                <div className="loader__circle loader__circle--2"></div>
            </div>
        );
    }
}

export default Loader;
