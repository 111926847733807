// @flow
import React from 'react';
import FormGroup from '../../shared/FormGroup';
import Classnames from 'classnames';
import { createCourseDownloadCertificatePath } from '../../../routing/urlGenerator';
import { slugify } from '../../../helper/slugHelper';

type Props = {}

type State = {
    name: string,
    companyName: string,
    errors: Object,
    locked: boolean,
    showThankYouMessage: boolean
}

class FinishedCourseForm extends React.Component<Props, State> {

    constructor(props: Object) {
        super(props);

        this.state = {
            name: '',
            companyName: '',
            errors: FinishedCourseForm.getClearedErrorState(),
            locked: false,
            showThankYouMessage: false
        };

        (this: any)._onSubmit = this._onSubmit.bind(this);
        (this: any)._onFormTextFieldChange = this._onFormTextFieldChange.bind(this);
    }

    static getClearedErrorState() {
        return {
            name: [],
            companyName: []
        };
    }

    _onFormTextFieldChange(fieldName: string, value: string) {
        this.setState({
            [fieldName]: value
        });
    }

    _validate(): boolean {
        var { name, companyName } = this.state;

        var errors = FinishedCourseForm.getClearedErrorState();
        var isValid = true;

        if (!name) {
            isValid = false;
            errors.name.push('Het is verplicht om een naam op te geven');
        }

        if (!companyName) {
            isValid = false;
            errors.companyName.push('Het is verplicht om een bedrijfsnaam op te geven');
        }

        if (isValid === false) {
            this.setState({ errors });
        }

        return isValid;
    }

    _reset(): void {
        this.setState({
            name: '',
            companyName: '',
            errors: FinishedCourseForm.getClearedErrorState()
        });
    }

    _onSubmit(event: Event) {
        event.preventDefault();

        if (this._validate()) {
            this._startCertificateDownload();
            this._reset();
        }
    }

    _startCertificateDownload(): void {
        var { name, companyName } = this.state;
        var downloadUrl = createCourseDownloadCertificatePath(name, companyName);

        // create fake link to trigger download
        var link = document.createElement('a');
        link.setAttribute('href', downloadUrl);
        link.setAttribute('download', `certificaat-preventie-medewerker-${slugify(name)}.pdf`);

        // Test if Custom Events can be used to dispatch a download event. IE11 will not support this and needs to open the link in a new tab.
        // this isn't preferred because this shows the link to create certificates.
        // dispatchEvent is used instead of regular click because some browsers will require the link to be part of the DOM which isn't possible in this case.
        if (typeof MouseEvent === 'function' && typeof link.download !== 'undefined') {
            link.dispatchEvent(new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            }));
        } else {
            // in case of missing support open link in new tab
            window.open(downloadUrl);
        }

        this.setState({
            showThankYouMessage: true
        });
    }

    render() {
        var { name, companyName, errors, locked, showThankYouMessage } = this.state;
        var submitButtonClassname = Classnames('button button--primary', {
            'is-locked': locked,
            'is-loading': locked
        });

        if (showThankYouMessage) {
            return (
                <div className="finished-course__form">
                    <div className="finished-course__form-header">
                        <h2 className="finished-course__form-title">Persoonlijk certificaat</h2>
                        <p className="finished-course__form-description">U hebt uw certificaat succesvol gedownload. Bedankt voor uw deelname en veel succes met u rol als preventiemedewerker!</p>
                    </div>
                </div>
            );
        }

        return (
            <form className="finished-course__form" onSubmit={ this._onSubmit }>
                <div className="finished-course__form-header">
                    <h2 className="finished-course__form-title">Persoonlijk certificaat</h2>
                    <p className="finished-course__form-description">Vul hieronder uw gegevens in en download direct uw persoonlijke certificaat.</p>
                </div>
                <div className="finished-course__form-fields">
                    <FormGroup
                        fieldName={ 'name' }
                        value={ name }
                        label={ 'Naam' }
                        placeholder={ 'Naam' }
                        errors={ errors.name }
                        onChangeHandler={ this._onFormTextFieldChange }
                    />
                    <FormGroup
                        fieldName={ 'companyName' }
                        value={ companyName }
                        label={ 'Bedrijfsnaam' }
                        placeholder={ 'Bedrijfsnaam' }
                        errors={ errors.companyName }
                        onChangeHandler={ this._onFormTextFieldChange }
                    />
                </div>
                <div className="finished-course__button-wrapper">
                    <button className={ submitButtonClassname } disabled={ locked }>Download certificaat</button>
                </div>
            </form>
        );
    }
}

export default FinishedCourseForm;
