// @flow
import React from 'react';
import {createMarkup} from '../../helper/createMarkup';
import classNames from 'classnames';

type Props = {
    title: string,
    intro: string,
    description: string
}

type State = {
    isExpanded: boolean
};

class ParagraphBlock extends React.Component<Props, State> {
    constructor(props: Object) {
        super(props);

        this.state = {
            isExpanded: false
        };

        (this: any)._handleClickDescriptionToggle = this._handleClickDescriptionToggle.bind(this);
    }

    _handleClickDescriptionToggle(e: Object) {
        e.preventDefault();
        var { isExpanded } = this.state;

        this.setState({
            isExpanded: !isExpanded
        });
    }

    _renderDescription() {
        var { isExpanded } = this.state;
        var { description } = this.props;

        if ( !isExpanded ) {
            return null;
        }

        return (
            <div className="paragraph__description" dangerouslySetInnerHTML={ createMarkup( description)} />
        );
    }

    _renderDescriptionToggle() {
        var { description } = this.props;
        var { isExpanded } = this.state;

        if (!description) {
            return null;
        }

        var buttonClass = classNames('paragraph__button button button--link', {
            'is-expanded': isExpanded
        });

        return (
            <button className={ buttonClass } onClick={ this._handleClickDescriptionToggle }>
                { isExpanded ? 'Lees minder' : 'Lees meer' }
            </button>
        );
    }

    render() {
        var { intro , title } = this.props;

        return (
            <div className='block paragraph wysiwyg'>
                <h2 className="paragraph__title">{ title }</h2>
                <div className="paragraph__intro" dangerouslySetInnerHTML={ createMarkup(intro) } />
                { this._renderDescription() }
                { this._renderDescriptionToggle() }
            </div>
        );
    }
}

export default ParagraphBlock;
