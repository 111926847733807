// polyfills
import 'core-js/fn/promise';
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/every';
import 'core-js/fn/array/includes';

// libraries
import docReady from 'doc-ready/doc-ready';
import WebFont from 'webfontloader';

// modules
import './CourseApp/index';

docReady(() => {
    WebFont.load({
        google: {
            families: ['Roboto:300,500,700']
        }
    });
});
