// @flow
import React from 'react';
import type { Chapter } from '../../../model/type/Chapter';
import { Link } from 'react-router-dom';

type Props = {
    className: string,
    route: string,
    chapter: Chapter,
    isFinished: boolean,
    onClickHandler: Function
};

export default class ChapterSidebarListItem extends React.Component<Props> {
    render() {
        var { chapter, isFinished, className, route, onClickHandler } = this.props;

        return (
            <li key={ chapter.id } className={ className }>
                <Link to={ route }
                      className="chapter-sidebar__link"
                      onClick={ onClickHandler }
                >
                    { chapter.rank }
                    { isFinished ? <span className="chapter-sidebar__link-finished"/> : ''}
                </Link>
            </li>
        );
    }
}
