// @flow
import * as localStorageRepository from '../repository/localStorageRepository';
import type { Action } from '../actions/types';
import type { GlobalState } from '../reducers/reducers';

type Next = (action: Action) => void;
type Store = {
    getState: () => GlobalState
};

export default (store: Store) => (next: Next) => (action: Action): any => {
    var response: any = next(action),
        globalState: GlobalState = store.getState();

    var stateToSave = {
        userProgress: globalState.userProgress
    };

    localStorageRepository.save(stateToSave);

    return response;
};
