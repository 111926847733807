// @flow
import React from 'react';
import type { Question } from '../../../model/type/Question';
import ExamQuestion from './ExamQuestion';

type Props = {
    currentQuestion: number,
    questions: Array<Question>,
    nextQuestionHandler: Function,
    resetQuestionHandler: Function,
    finishExamHandler: Function
}

class ExamQuestionsDeck extends React.Component<Props> {
    render() {
        var { questions, currentQuestion, nextQuestionHandler, resetQuestionHandler, finishExamHandler } = this.props;

        return (
            <div className="exam__questions-deck">
                <div className="exam__questions">
                    { questions.map((question, index) => (
                        <ExamQuestion
                            key={ question.id }
                            index={ index }
                            question={ question }
                            currentQuestion={ currentQuestion }
                            isCurrentQuestion={ currentQuestion === index }
                            isLastQuestion={ index === (questions.length - 1) }
                            nextQuestionHandler={ nextQuestionHandler }
                            resetQuestionHandler={ resetQuestionHandler }
                            finishExamHandler={ finishExamHandler }
                            totalQuestions={ questions.length} />
                    ))}
                </div>
            </div>
        );
    }
}

export default ExamQuestionsDeck;
