import React from 'react';
import { createCourseChapterPath, createChapterExamPath } from '../routing/urlGenerator';
import ChapterDetail from './chapter/ChapterDetail';
import ExamDetail from './exam/ExamDetail';
import { Route } from 'react-router-dom';
import ErrorBoundary from './shared/ErrorBoundary';

class CourseApp extends React.Component {
    render() {
        return (
            <ErrorBoundary>
                <Route exact path={ createCourseChapterPath() } component={ ChapterDetail } />
                <Route exact path={ createChapterExamPath() } component={ ExamDetail } />
            </ErrorBoundary>
        );
    }
}

export default CourseApp;
