// @flow
import React from 'react';

type Props = {
    title: string,
    intro: string,
    rank: number
}

class ChapterHeader extends React.Component<Props> {
    render() {
        var { title, rank, intro} = this.props;

        return (
            <div className="chapter-header">
                <div className="container">
                    <div className="layout layout--align-center">
                        <div className="layout__item layout__item--md-9 layout__item--lg-10 layout__item--xl-8">
                            <div className="chapter-header__content">
                                <div className="chapter-header__rank-wrapper">
                                    <span className="chapter-header__rank">{ rank }</span>
                                </div>
                                <div className="chapter-header__text">
                                    <h1>{ title }</h1>
                                    <p>{ intro }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChapterHeader;
