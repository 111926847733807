import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createHistory from 'history/createBrowserHistory';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { createStore } from './store/storeFactory';
import { createCourseEntryPath, createCourseFinishedPath } from './routing/urlGenerator';
import CourseApp from './components/CourseApp';
import * as localStorageRepository from './repository/localStorageRepository';
import FinishedCourse from './components/FinishedCourse';

var containerEl = document.getElementById('js-course-app-container');

if (containerEl) {
    var initialState = {
        currentSite: {
            identifier: containerEl.getAttribute('data-current-site-identifier'),
            title: containerEl.getAttribute('data-current-site-title'),
            spritemapPath: containerEl.getAttribute('data-spritemap-path')
        }
    };

    var localStorageState = localStorageRepository.getState();

    var history = createHistory(),
        store = createStore(history, {
            ...initialState,
            ...localStorageState
        });

    ReactDOM.render(
        <Provider store={ store }>
            <ConnectedRouter history={ history }>
                <React.Fragment>
                    <Route path={ createCourseEntryPath() } component={ CourseApp } />
                    <Route exact path={ createCourseFinishedPath() } component={ FinishedCourse } />
                </React.Fragment>
            </ConnectedRouter>
        </Provider>,
        containerEl
    );
}
