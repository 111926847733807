// @flow
import React from 'react';
import ImageBlock from '../../block/ImageBlock';
import ParagraphBlock from '../../block/ParagraphBlock';
import { BLOCK_IMAGE_TYPE, BLOCK_PARAGRAPH_TYPE } from '../../../model/type/Block';

type Props = {
    blocks: Array<Object>
};

class ChapterBlocks extends React.Component<Props> {
    _renderBlocks() {
        var { blocks } = this.props;

        return blocks.map((block) => {
            switch (block.type) {
                case BLOCK_IMAGE_TYPE:
                    return <ImageBlock key={ block.id } html={ block.html } />;
                case BLOCK_PARAGRAPH_TYPE:
                    return <ParagraphBlock
                                key={ block.id }
                                title={ block.title }
                                intro={ block.intro }
                                description={ block.text }
                            />;
                default:
                    return null;
            }
        });
    }

    render() {
        if (this.props.blocks.length === 0) {
            return null;
        }

        return (
            <div className="chapter__blocks">
                <div className="container">
                    <div className="layout layout--align-center">
                        <div className="layout__item layout__item--md-9 layout__item--lg-10 layout__item--xl-8">
                            { this._renderBlocks() }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChapterBlocks;
