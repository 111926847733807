// @flow
import type { Chapter } from '../model/type/Chapter';
import type { FinishedChapter } from '../model/type/FinishedChapter';

export function isUserAllowedToStartExam(currentChapter: Chapter, finishedChapters: Array<FinishedChapter>): boolean {
    var highestFinishedChapterRank = Math.max(...finishedChapters.map(finishedChapter => finishedChapter.rank), 0);
    var allowedChapterRank = highestFinishedChapterRank + 1; // user can start the exam of the next chapter that is finished

    if (currentChapter.rank > allowedChapterRank) {
        return false;
    }

    return true;
}
