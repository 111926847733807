// @flow
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import chaptersReducer from './chaptersReducer';
import currentSiteReducer from './currentSiteReducer';
import examReducer from './examReducer';
import userProgressReducer from './userProgressReducer';
import type { ChaptersReducerState } from './chaptersReducer';
import type { ExamReducerState } from './examReducer';
import type { UserProgressReducerState } from './userProgressReducer';
import type { CurrentSiteReducerState } from './currentSiteReducer';

export type GlobalState = {
    chapters: ChaptersReducerState,
    exam: ExamReducerState,
    currentSite: CurrentSiteReducerState,
    userProgress: UserProgressReducerState
};

const rootReducer = combineReducers({
    router: routerReducer,
    chapters: chaptersReducer,
    exam: examReducer,
    currentSite: currentSiteReducer,
    userProgress: userProgressReducer
});

export default rootReducer;
