// @flow
export const FETCH_CHAPTERS = 'FETCH_CHAPTERS';
export const FETCH_EXAM = 'FETCH_EXAM';
export const FINISH_CHAPTER = 'FINISH_CHAPTER';
export const NEXT_EXAM_QUESTION = 'NEXT_EXAM_QUESTION';
export const RESET_EXAM_QUESTION = 'RESET_EXAM_QUESTION';
export const CLEAR_EXAM = 'CLEAR_EXAM';

export type Action = $ReadOnly<{
    type: string,
    payload: Object,
    meta: ?Object
}>;

export type Dispatch = (action: Action) => any;
