// @flow
import React from 'react';
import Classnames from 'classnames';
import type { Chapter } from '../../../model/type/Chapter';
import ChapterSidebarHeader from './ChapterSidebarHeader';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import ChapterSidebarContent from './ChapterSidebarContent';
import type { CurrentSite } from '../../../model/type/CurrentSite';
import type { FinishedChapter } from '../../../model/type/FinishedChapter';

type Props = {
    linksAreDisabled: boolean,
    chapters: Array<Chapter>,
    currentChapter: Chapter,
    currentSite: CurrentSite,
    finishedChapters: Array<FinishedChapter>
}

type State = {
    isMobileView: boolean,
    isActive: boolean
}

class ChapterSidebar extends React.Component<Props, State> {
    constructor(props: Object) {
        super(props);

        this.state = {
            isMobileView: this._isMobileView(),
            isActive: false
        };

        (this: any)._toggleIsActive = this._toggleIsActive.bind(this);
        (this: any)._updateMobileViewState = this._updateMobileViewState.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this._updateMobileViewState);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._updateMobileViewState);
    }

    _toggleIsActive(e: Event) {
        if (this.props.linksAreDisabled) {
            e.preventDefault();
        }

        this.setState({
            isActive: !this.state.isActive
        });
    }

    _updateMobileViewState() {
        this.setState({
            isMobileView: this._isMobileView()
        });
    }

    _isMobileView() {
        return window.innerWidth < BREAKPOINTS.lg;
    }

    render() {
        var { currentChapter, chapters, finishedChapters, currentSite, linksAreDisabled} = this.props;
        var { isActive, isMobileView } = this.state;
        var className = Classnames('chapter-sidebar', {
            'is-active': isActive && isMobileView
        });

        return (
            <aside className={ className }>
                <ChapterSidebarHeader
                    currentSite={ currentSite }
                    onClickHandler={ this._toggleIsActive }
                />
                <ChapterSidebarContent
                    currentChapter={ currentChapter }
                    chapters={ chapters }
                    finishedChapters={ finishedChapters }
                    isActive={ isActive }
                    isMobileView={ isMobileView }
                    linksAreDisabled={ linksAreDisabled }
                    onClickHandler={ this._toggleIsActive }/>
            </aside>
        );
    }
}

export default ChapterSidebar;
