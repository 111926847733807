// @flow
import React from 'react';
import type { Answer } from '../../../model/type/Answer';
import Classnames from 'classnames';

type Props = {
    answer: Answer,
    onClickHandler?: Function
}

class ExamQuestionAnswer extends React.Component<Props> {
    onClick() {
        var { answer, onClickHandler } = this.props;

        if (onClickHandler) {
            onClickHandler(answer);
        }
    }

    render() {
        var { answer, onClickHandler } = this.props;
        var answerClassName = Classnames('exam__question-answer', {
            'is-clickable': onClickHandler
        });

        return (
            <div className={ answerClassName } onClick={ this.onClick.bind(this) }>
                <div className="exam__question-answer-rank">
                    { answer.letter }
                </div>
                <div className="exam__question-answer-text">
                    { answer.title }
                </div>
            </div>
        );
    }
}

export default ExamQuestionAnswer;
