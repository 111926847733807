// @flow
import React from 'react';
import { createChapterExamPath } from '../../../routing/urlGenerator';
import Classnames from 'classnames';
import { isUserAllowedToStartExam } from '../../../helper/userProgressValidationHelper';
import { Link } from 'react-router-dom';
import type { Chapter } from '../../../model/type/Chapter';
import type { FinishedChapter } from '../../../model/type/FinishedChapter';

type Props = {
    currentChapter: Chapter,
    finishedChapters: Array<FinishedChapter>
};

class ChapterExamButton extends React.Component<Props> {
    render() {
        var { currentChapter, finishedChapters } = this.props;

        if (!currentChapter.hasExam) {
            return null;
        }

        var classNames = Classnames('button button--primary button--small', {
            'is-disabled': !isUserAllowedToStartExam(currentChapter, finishedChapters)
        });

        return (
            <Link to={ createChapterExamPath(currentChapter.slug) }
                  className={ classNames }>Maak toets { currentChapter.rank }</Link>
        );
    }
}

export default ChapterExamButton;
