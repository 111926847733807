// @flow
import React from 'react';
import { createHomePath } from '../../../routing/urlGenerator';
import Logo from '../../shared/Logo';
import type { CurrentSite } from '../../../model/type/CurrentSite';

type Props = {
    currentSite: CurrentSite
}

class FinishedCourseHeader extends React.Component<Props> {
    render() {
        var { currentSite } = this.props;

        return (
            <div className="finished-course__header header">
                <div className="container container-xl">
                    <a href={ createHomePath() }>
                        <Logo identifier={ currentSite.identifier } className="header__logo" />
                    </a>
                </div>
            </div>
        );
    }
}

export default FinishedCourseHeader;
