// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { Chapter } from '../../model/type/Chapter';
import withChapters from '../../hoc/withChapters';
import type { ExamReducerState } from '../../reducers/examReducer';
import type { Action } from '../../actions/types';
import {
    createClearChapterExamAction,
    createFetchChapterExamAction,
    createFinishChapterAction,
    createNextExamQuestionAction,
    createResetExamQuestionAction
} from '../../actions/chapterActionFactory';
import Loader from '../shared/Loader';
import ExamHeader from './components/ExamHeader';
import ExamQuestionsDeck from './components/ExamQuestionsDeck';
import { createCourseChapterPath, createCourseFinishedPath } from '../../routing/urlGenerator';
import { push } from 'react-router-redux';
import ExamProgress from './components/ExamProgress';
import type { GlobalState } from '../../reducers/reducers';
import type { CurrentSiteReducerState } from '../../reducers/currentSiteReducer';
import { renderChapterExamSeoTags } from '../../helper/seoHelper';
import type { UserProgressReducerState } from '../../reducers/userProgressReducer';
import { isUserAllowedToStartExam } from '../../helper/userProgressValidationHelper';

type Props = {
    exam: ExamReducerState,
    currentSite: CurrentSiteReducerState,
    userProgress: UserProgressReducerState,
    chapters: Array<Chapter>,
    currentChapter: Chapter,
    dispatch: (action: Action) => void
}

type ReduxProps = {
    exam: ExamReducerState,
    currentSite: CurrentSiteReducerState,
    userProgress: UserProgressReducerState
}

class ExamDetail extends React.Component<Props> {

    constructor(props) {
        super(props);

        (this: any)._goToNextQuestion = this._goToNextQuestion.bind(this);
        (this: any)._resetQuestion = this._resetQuestion.bind(this);
        (this: any)._handleFinishExam = this._handleFinishExam.bind(this);
    }

    componentDidMount() {
        var { currentChapter, userProgress } = this.props;

        if (isUserAllowedToStartExam(currentChapter, userProgress.finishedChapters)) {
            this.props.dispatch(
                createFetchChapterExamAction(currentChapter.slug)
            );
        } else {
            throw new Error('U moet eerst de voorgaande hoofdstukken afronden voor u deze toets kan maken.');
        }
    }

    componentWillUnmount() {
        // clear exam in global state, to make sure that, next time this component is loaded, the
        // data is refreshed, as it might be the data for another exam.
        this.props.dispatch(
            createClearChapterExamAction()
        );
    }

    _goToNextQuestion() {
        this.props.dispatch(
            createNextExamQuestionAction()
        );
    }

    _resetQuestion() {
        this.props.dispatch(
            createResetExamQuestionAction()
        );
    }

    _handleFinishExam(): void {
        var { chapters, currentChapter } = this.props;
        var nextChapter = chapters[chapters.indexOf(currentChapter) + 1];

        this.props.dispatch(
            createFinishChapterAction(currentChapter)
        );

        if (!nextChapter) {
            this.props.dispatch(
                push(createCourseFinishedPath())
            );

            return;
        }

        this.props.dispatch(
            push(createCourseChapterPath(nextChapter.slug))
        );
    }

    render() {
        var { currentChapter, exam, currentSite } = this.props;

        if (exam === null) {
            return (
                <div className="loader-fixed">
                    <Loader/>
                </div>
            );
        }

        return (
            <div className="exam">
                { renderChapterExamSeoTags(currentChapter, currentSite.title) }
                <ExamHeader chapter={ currentChapter }/>
                <ExamQuestionsDeck
                    questions={ exam.questions }
                    currentQuestion={ exam.currentQuestion }
                    nextQuestionHandler={ this._goToNextQuestion }
                    resetQuestionHandler={ this._resetQuestion }
                    finishExamHandler={ this._handleFinishExam }
                />
                <ExamProgress
                    currentQuestion={ exam.currentQuestion }
                    totalQuestions={ exam.questions.length }
                />
            </div>
        );
    }
}

function _mapGlobalStateToProps(globalState: GlobalState): ReduxProps {
    return {
        exam: globalState.exam,
        currentSite: globalState.currentSite,
        userProgress: globalState.userProgress
    };
}

export default withChapters(
    connect(_mapGlobalStateToProps)(ExamDetail)
);
