// @flow

import type { Action } from '../actions/types';
import { FINISH_CHAPTER } from '../actions/types';
import type { FinishedChapter } from '../model/type/FinishedChapter';

export type UserProgressReducerState = {
    finishedChapters: Array<FinishedChapter>
}

const INITIAL_STATE = {
    finishedChapters: []
};

export default function(currentState: UserProgressReducerState = INITIAL_STATE, action: Action): UserProgressReducerState {
    switch (action.type) {
        case FINISH_CHAPTER:
            return {
                ...currentState,
                finishedChapters: [...currentState.finishedChapters, { id: action.payload.data.id, rank: action.payload.data.rank }]
            };
        default:
            return currentState;
    }
}
