// @flow
import type { Action } from './types';

export function createAction(
    type: string,
    promise: ?Promise<any> | null = null,
    data: ?Object = {},
    meta: ?Object = {}
): Action {
    return {
        type,
        payload: {
            data: data,
            promise: promise
        },
        meta
    };
}
