// @flow

import React from 'react';
import { createFetchChaptersAction } from '../actions/chapterActionFactory';
import type { Chapter } from '../model/type/Chapter';
import type { ChaptersReducerState } from '../reducers/chaptersReducer';
import type { Action } from '../actions/types';
import { connect } from 'react-redux';
import Loader from '../components/shared/Loader';
import type { GlobalState } from '../reducers/reducers';

type Props = {
    chapters: ChaptersReducerState,
    match: Object,
    dispatch: (action: Action) => Promise<Function>
}

type ReduxProps = {
    chapters: ChaptersReducerState
}

type State = {
    currentChapter: Chapter | null
}

export default function(ComposedComponent: any ) {
    class WrapperComponent extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);

            this.state = {
                currentChapter: null
            };
        }

        componentDidMount() {
            this.props.dispatch(
                createFetchChaptersAction()
            ).then(() => this._setCurrentChapterByRoute());
        }

        componentDidUpdate(prevProps) {
            if (this.props.match.url !== prevProps.match.url) {
                this._setCurrentChapterByRoute();
            }
        }

        _setCurrentChapterByRoute() {
            var { match, chapters } = this.props;
            var currentSlug = match.params.slug;

            if (currentSlug && chapters) {
                var currentChapter = chapters.find((chapter) => chapter.slug === currentSlug);

                this.setState({
                    currentChapter: currentChapter
                });
            }
        }

        render() {
            var { chapters } = this.props;
            var { currentChapter } = this.state;

            if (chapters === null || currentChapter === null) {
                return (
                    <div className="loader-fixed">
                        <Loader/>
                    </div>
                );
            }

            // Pass along the props that we want to go the the wrapped component
            return (
                <ComposedComponent {...this.props} currentChapter={ currentChapter } />
            );
        }
    }

    function _mapGlobalStateToProps(globalState: GlobalState): ReduxProps {
        return {
            chapters: globalState.chapters
        };
    }

    return connect(_mapGlobalStateToProps)(WrapperComponent);
}
