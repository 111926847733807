// @flow
import React from 'react';
import Classnames from 'classnames';

type Props = {
    totalQuestions: number,
    currentQuestion: number
}

class ExamProgress extends React.Component<Props> {

    _renderDots() {
        var { totalQuestions, currentQuestion } = this.props;
        var dots = [];

        if ( totalQuestions <= -1) {
            return null;
        }

        for (let i = 0; i < totalQuestions; i++) {
            var dotClassName = Classnames('exam-progress__dot', {
                'is-active': i === currentQuestion,
                'is-finished': i < currentQuestion
            });

            dots.push(
                <span key={i} className={ dotClassName }/>
            );
        }

        return dots;
    }

    render() {
        return (
            <div className="exam-progress">
                <div className="exam-progress__dots">
                    { this._renderDots() }
                </div>
            </div>
        );
    }
}

export default ExamProgress;
