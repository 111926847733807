// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { GlobalState } from '../reducers/reducers';
import type { ChaptersReducerState } from '../reducers/chaptersReducer';
import { createFetchChaptersAction } from '../actions/chapterActionFactory';
import Loader from '../components/shared/Loader';
import { applyFinishedChapterIdsSelector } from '../model/selectors/userProgressSelectors';
import { createCourseEntryPath } from '../routing/urlGenerator';
import type { Dispatch } from '../actions/types';

type Props = {
    finishedChapterIds: Array<number>,
    chapters: ChaptersReducerState,
    dispatch: Dispatch
};

export default function requireFinishedAllExams(ComposedComponent: any ) {
    class RequireFinishedAllExams extends React.Component<Props> {
        componentDidMount() {
            this.props.dispatch(
                createFetchChaptersAction()
            );
        }

        _isChapterFinished(chapter) {
            var { finishedChapterIds } = this.props;

            return finishedChapterIds.includes(chapter.id);
        }

        render() {
            var { chapters } = this.props;

            if (chapters === null) {
                return (
                    <div className="loader-fixed">
                        <Loader/>
                    </div>
                );
            }
            var userFinishedAllChapters = chapters.every(this._isChapterFinished.bind(this));

            if (!userFinishedAllChapters) {
                // @todo show notification after refresh
                window.location = createCourseEntryPath();

                return null;
            }

            return <ComposedComponent/>;
        }
    }

    function _mapGlobalStateToProps(globalState: GlobalState) {
        return {
            finishedChapterIds: applyFinishedChapterIdsSelector(globalState.userProgress),
            chapters: globalState.chapters
        };
    }

    return connect(_mapGlobalStateToProps)(RequireFinishedAllExams);
}
