// @flow

import uuid from 'uuid';

import type { Block } from '../type/Block';
import type { ParagraphBlock } from '../type/ParagraphBlock';
import type { ImageBlock } from '../type/ImageBlock';
import { BLOCK_IMAGE_TYPE, BLOCK_PARAGRAPH_TYPE } from '../type/Block';

function _createImageBlock(block: Object): ImageBlock {
    return {
        id: uuid(),
        type: block.type,
        html: block.html
    };
}

function _createParagraphBlock(block: Object): ParagraphBlock {
    return {
        id: uuid(),
        type: block.type,
        title: block.title,
        intro: block.intro,
        text: block.text
    };
}

function _createBlockFromApiData(block: Object): Block {
    if (block.type === BLOCK_PARAGRAPH_TYPE) {
        return _createParagraphBlock(block);
    } else if (block.type === BLOCK_IMAGE_TYPE) {
        return _createImageBlock(block);
    }

    throw new Error('unknown block in apiData');
}

export function createBlocksFromApiData(apiInput: Object): Array<Block> {
    return apiInput.map((block) => _createBlockFromApiData(block));
}
