// @flow
import React from 'react';
import { createMarkup } from '../../helper/createMarkup';

type Props = {
    html: string
};

class ImageBlock extends React.Component<Props> {
    render() {
        var { html } = this.props;

        return (
            <div className="block" dangerouslySetInnerHTML={ createMarkup(html)}></div>
        );
    }
}

export default ImageBlock;
