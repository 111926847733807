// @flow
import uuid from 'uuid';
import type { Exam } from '../type/Exam';
import type { Question } from '../type/Question';
import type { Answer } from '../type/Answer';
import { convertNumberToLetter } from '../../helper/convertNumberToLetter';
import { shuffle } from '../../helper/shuffleArrayHelper';

export function createExamFromApiInput(apiInput: Object): Exam {
    return {
        id: apiInput.id,
        currentQuestion: 0,
        questions: apiInput.questions.map((question) => _createQuestionFromApiInput(question))
    };
}

function _createQuestionFromApiInput(apiInput: Object): Question {
    var shuffledAnswers = shuffle(apiInput.answers);

    return {
        id: uuid(),
        title: apiInput.title,
        explanation: apiInput.explanation,
        answers: shuffledAnswers.map((answer, index) => _createAnswerFromApiInput(answer, index))
    };
}

function _createAnswerFromApiInput(apiInput: Object, index: number): Answer {
    return {
        id: uuid(),
        title: apiInput.title,
        letter: convertNumberToLetter(index),
        isCorrect: apiInput.isCorrect
    };
}
