// @flow
import { createAction } from './factory';
import {
    CLEAR_EXAM,
    FETCH_CHAPTERS,
    FETCH_EXAM,
    FINISH_CHAPTER,
    NEXT_EXAM_QUESTION,
    RESET_EXAM_QUESTION
} from './types';
import * as apiClient from '../client/apiClient';
import { createChapterIndexApiPath, createGetChapterExamApiPath } from '../routing/urlGenerator';
import type { Chapter } from '../model/type/Chapter';

export function createFetchChaptersAction(): Function {
    return (dispatch) => {
        var promise = apiClient.get(createChapterIndexApiPath());
        var action = createAction(FETCH_CHAPTERS, promise);

        dispatch(action);

        return promise;
    };
}

export function createFetchChapterExamAction(chapterSlug: string): Function {
    return (dispatch) => {
        var promise = apiClient.get(createGetChapterExamApiPath(chapterSlug));
        var action = createAction(FETCH_EXAM, promise);

        return dispatch(action);
    };
}

export function createClearChapterExamAction() {
    return createAction(CLEAR_EXAM);
}

export function createNextExamQuestionAction() {
    return createAction(NEXT_EXAM_QUESTION);
}

export function createResetExamQuestionAction() {
    return createAction(RESET_EXAM_QUESTION);
}

export function createFinishChapterAction(chapter: Chapter) {
    return createAction(FINISH_CHAPTER, null, chapter);
}
