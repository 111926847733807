// @flow
import React from 'react';
import { connect } from 'react-redux';
import ChapterSidebar from './components/ChapterSidebar';
import type { Chapter } from '../../model/type/Chapter';
import withChapters from '../../hoc/withChapters';
import ChapterContent from './components/ChapterContent';
import { renderChapterSeoTags } from '../../helper/seoHelper';
import type { GlobalState } from '../../reducers/reducers';
import type { CurrentSiteReducerState } from '../../reducers/currentSiteReducer';
import type { UserProgressReducerState } from '../../reducers/userProgressReducer';

type Props = {
    chapters: Array<Chapter>,
    currentChapter: Chapter,
    currentSite: CurrentSiteReducerState,
    userProgress: UserProgressReducerState
}

type State = {
    linksAreDisabled: boolean
}

type ReduxProps = {
    currentSite: CurrentSiteReducerState,
    userProgress: UserProgressReducerState
}

class ChapterDetail extends React.Component<Props, State> {
    constructor(props: Object) {
        super(props);

        this.state = {
            linksAreDisabled: false
        };

        (this: any)._disableLinks = this._disableLinks.bind(this);
    }

    _disableLinks(disabled: boolean) {
        this.setState({
            linksAreDisabled: disabled
        });
    }

    render() {
        var { chapters, currentChapter, currentSite, userProgress } = this.props;
        var { linksAreDisabled } = this.state;

        return (
            <div className="chapter">
                { renderChapterSeoTags(currentChapter, currentSite.title) }
                <ChapterSidebar
                    chapters={ chapters }
                    finishedChapters={ userProgress.finishedChapters }
                    currentSite={ currentSite }
                    currentChapter={ currentChapter }
                    linksAreDisabled={ linksAreDisabled }
                />
                <ChapterContent
                    currentChapter={ currentChapter }
                    finishedChapters={ userProgress.finishedChapters }
                    onAnimationStart={ this._disableLinks.bind(this, true)}
                    onAnimationEnd={ this._disableLinks.bind(this, false) }
                />
            </div>
        );
    }
}

function _mapGlobalStateToProps(globalState: GlobalState): ReduxProps {
    return {
        currentSite: globalState.currentSite,
        userProgress: globalState.userProgress
    };
}

export default withChapters(
    connect(_mapGlobalStateToProps)(ChapterDetail)
);
