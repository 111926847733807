// @flow
import React from 'react';
import type { Chapter } from '../model/type/Chapter';
import { Helmet } from 'react-helmet';

const DEFAULT_TITLE = 'Preventiemedewerker';

function _getChapterSeoTitleTag(chapter: Chapter, currentSiteTitle: string) {
    if (!chapter.seoMetadata || !chapter.seoMetadata.title) {
        return <title>{ `${chapter.title} - ${DEFAULT_TITLE} - ${currentSiteTitle}` }</title>;
    }

    return <title>{ `${chapter.seoMetadata.title} - ${DEFAULT_TITLE} - ${currentSiteTitle}` }</title>;
}

function _getChapterSeoMetaDescriptionTag(chapter: Chapter) {
    if (!chapter.seoMetadata || !chapter.seoMetadata.metaDescription) {
        return null;
    }

    return <meta name="description" content={ chapter.seoMetadata.metaDescription } />;
}

export function renderChapterSeoTags(chapter: Chapter, currentSiteTitle: string) {
    // @todo should we add a canonical tag to prevent duplicate content - disadvantage is that only one brand is shown in google
    return (
        <Helmet>
            { _getChapterSeoTitleTag(chapter, currentSiteTitle) }
            { _getChapterSeoMetaDescriptionTag(chapter) }
        </Helmet>
    );
}

export function renderChapterExamSeoTags(chapter: Chapter, currentSiteTitle: string) {
    return (
        <Helmet>
            <title>{ `Toets ${chapter.title} - ${DEFAULT_TITLE} - ${currentSiteTitle}`}</title>
        </Helmet>
    );
}
