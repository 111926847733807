// @flow
import React from 'react';
import Classnames from 'classnames';

type Props = {
    fieldName: string,
    value: string,
    label: string,
    errors: Array<string>,
    onChangeHandler: Function,
    placeholder?: string,
    type?: string
}

class FormGroup extends React.Component<Props> {

    constructor(props: Object) {
        super(props);

        (this: any)._handleChange = this._handleChange.bind(this);
    }

    _handleChange(e: Object) {
        e.preventDefault();
        var { fieldName } = this.props;
        var { value } = (this: any)._formField;

        this.props.onChangeHandler(
            fieldName,
            value
        );
    }

    _renderFormFieldErrors() {
        var { errors } = this.props;

        if (!errors.length) {
            return null;
        }

        return (
            errors.map((error, index) => {
                return <span key={index} className="form__error-message">{ error }</span>;
            })
        );
    }

    render() {
        var { fieldName, type, value, placeholder, errors, label } = this.props;
        var formGroupClassName = Classnames('form__group', {
            'has-error': errors.length
        });

        return (
            <div className={ formGroupClassName }>
                <label htmlFor={ fieldName } className="form__label">{ label }</label>
                <input
                    id={ fieldName }
                    type={ type ? type : 'text' }
                    value={ value }
                    className="form-field"
                    placeholder={ placeholder ? placeholder : '' }
                    ref={ (formField) => (this: any)._formField = formField }
                    onChange={ this._handleChange }
                />
                { this._renderFormFieldErrors() }
            </div>
        );
    }
}

export default FormGroup;
