// @flow
import React from 'react';
import FinishedCourseForm from './FinishedCourseForm';

type Props = {}

class FinishedCourseContent extends React.Component<Props> {
    render() {
        return (
            <div className="finished-course__content">
                <div className="container container-xl">
                    <div className="layout layout--align-center">
                        <div className="layout__item layout__item--md-8 layout__item--xl-6">
                            <h1 className="finished-course__title">Gefeliciteerd! U hebt de cursus succesvol afgerond!</h1>
                            <FinishedCourseForm />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FinishedCourseContent;
